<template>
  <PageListLayout @reset-filter="resetFilter">
    <template slot="header_right">
      <v-list-item three-line>
        <v-layout align-center>
          <v-img
            max-height="150"
            max-width="150"
            :src="BaoCaoPic"
            class="mr-4"
          ></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 black--text">
              BÁO CÁO HỆ THỐNG</v-list-item-title
            >
            <v-list-item-subtitle
              >Báo cáo tổng hợp theo thời gian</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-layout>
      </v-list-item>
      <div class="d-flex">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-1 mt-12" icon v-on="on" @click="exportExcel()">
              <v-icon> mdi-file-export-outline </v-icon>
            </v-btn>
          </template>
          <span>Xuất Excel</span>
        </v-tooltip>
      </div>
    </template>
    <template slot="filter">
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.year"
          placeholder="Chọn năm"
          auto-select-first
          :items="years"
          outlined
          solo
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.month"
          :items="this.monthList"
          placeholder="Chọn tháng"
          item-text="name"
          item-value="id"
          solo
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.staff_id"
          :items="employers"
          placeholder="Nhân viên phụ trách"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.area_id"
          :items="areasData"
          placeholder="khu vực"
          item-text="name"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </div>
    </template>
    <CustomTable
      :headers="headers"
      :items="desserts"
      :loading="isloading"
      :loading-text="loadingText"
      :page.sync="page"
      :items-per-page=50
      hide-default-footer
      @change-page="changePage"
      classPaging="mt-2"
    ></CustomTable>
  </PageListLayout>
</template>
<script>
import BaoCaoPic from "../../../assets/images/baocao.svg";
import { monthList } from "@/constants/time";
import Report from "@/api/report.js";
import { generateArrayOfYears } from "@/utils/date.js";
import { getAllAreas } from "@/api/khuvuc";
import { getNhanVienKinhDoanh } from "@/api/nhanvien";
import { getDeclationBill, exportDeclation } from "@/api/report.js";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      page: 1,
      BaoCaoPic,
      monthList,
      years: generateArrayOfYears(10),
      areasData: [],
      employers: [],
      formSearch: {
        year: new Date().getFullYear(),
        month: "",
        staff_id: [],
        area_id: [],
      },
      isloading: false,
      search: "",
      calories: "",
      data: {},
      data_render: [],
      desserts: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Năm", value: "year" },
        { text: "Tháng", value: "month" },
        { text: "Nhân viên", value: "staff" },
        { text: "khu vực", value: "area" },
        {
          text: "Số lượng tờ khai kế hoạch",
          align: "start",
          value: "declaration_plan",
          filter: (value) => {
            if (!this.calories) return true;
            return value < parseInt(this.calories);
          },
        },
        {
          text: "Số lượng tờ khai thực tế",
          value: "declaration_reality",
        },
        {
          text: "Số lượng lô hàng hủy kế hoạch",
          value: "Declation_cancel_plan",
        },
        { text: "Số CTCTNH theo kế hoạch", value: "CTCTNH_plan" },
        { text: "Số CTCTNH hoàn thành thực tế", value: "CTCTNH_reality" },
      ];
    },
  },
  created() {
    this.isloading = true;
    this.getAllEmployee();
    this.getAllArea();
    this.getData()
    this.isloading = false;
  },
  methods: {
    changePage(val) {
      this.page = val;
    },
    async getData() {
      try {
        this.isloading = true;
        const res = await Report.getDeclationBill(this.formSearch);
        this.data = res.data;
        this.desserts=[];
        this.loadData(this.data);
        this.isloading = false;
      } catch (error) {
        console.error(error);
      }
    },
    loadData(target) {
      target.forEach((item) => {
        if (item.staff == null) {
          item.staff = "null";
        }
        if (item.area == null) {
          item.area = "null";
        }
        this.desserts.push({
          year: item.year,
          month: item.month,
          staff: item.staff,
          area: item.area,
          declaration_plan: item.declaration_plan,
          declaration_reality: item.declaration_reality,
          Declation_cancel_plan: item.Declation_cancel_plan,
          CTCTNH_plan: item.CTCTNH_plan,
          CTCTNH_reality: item.CTCTNH_reality,

          // prob_complaint_done: item.prob_complaint_done,
          // average_complaint_time: item.average_complaint_time,
          // prob_request_done: item.prob_request_done,
          // average_request_time: item.average_request_time,
          // prob_complaint_feedback: item.prob_complaint_feedback
        });
      });
    },
   
    async exportExcel() {
      try {
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        let newdate = day + "/" + month + "/" + year;
          const res = await Report.exportDeclation(this.formSearch);
          if(res && res.isDone){
            saveAs(new Blob([res]), "BaoCaoChungTuHuyHang"+"-"+newdate+".xlsx");
          }
        this.$toast.success("Xuất báo cáo thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        this.$toast.error("lỗi" + " " + error, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          message: error,
        });
      }
    },
    async getAllEmployee() {
      try {
        const res = await getNhanVienKinhDoanh();
        this.employers = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllArea() {
      let data = await getAllAreas();
      this.areasData = data;
    },
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
  },
  watch: {
    formSearch: {
      handler: function (newValue) {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style>
.app {
  margin-top: 10px;
}
</style>